<template>
  <div class="shipment">
    <Header @change_shop="changeShop" />
    <pageTitle txt="荷物番号確認" :backBtn="backBtn" />
    <ul class="shipment-list">
      <li class="shipment-list__item" v-for="(row, key) in list" :key="key">
        <span class="shipment-list__date">
          {{ new Date(row.date) | dateFormat }} {{ row.time }}
        </span>
        <span class="shipment-list__num">荷物番号 : {{ row.code }}</span>
        <a>
          <btnL
            class="home-btns__item-L"
            btnTxt="現在の状況を表示する"
            color="positive1"
            icon="external"
            type="button"
            @click="show_sagawa(row.code)"
          />
        </a>
        <span class="shipment-list__cap"
          >佐川急便のサイトにジャンプします。</span
        >
      </li>
    </ul>
    <p class="message" v-if="list.length < 1">
      現在、表示する荷物番号はございません
    </p>
  </div>
</template>
<script>
import Header from "@/components/shop/header";
import pageTitle from "@/components/shop/pageTitle";
import btnL from "@/components/btn/btnL";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "orderHistory",
  components: {
    Header,
    pageTitle,
    btnL
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      list: []
    };
  },
  async created() {
    this._redraw();
  },
  methods: {
    changeShop() {
      this._redraw();
    },
    show_sagawa(code) {
      window.open(
        "https://k2k.sagawa-exp.co.jp/p/web/okurijosearch.do?okurijoNo=" + code
      );
    },
    async _redraw() {
      const api = new ApiClient();
      const state = await this.$store.getters["getStoreLoginData"];
      const param = {
        store_id: state.shop_id
      };
      const result = await api.post("/store/shipment/list", param);
      this.list = result.list;
    }
  }
};
</script>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.shipment-list {
  max-width: 375px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 30px;
}
.shipment-list__item {
  box-sizing: border-box;
  padding: 20px 0 30px;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px variables.$bg-gray-2;
}
.shipment-list__date {
  display: block;
  @extend .f_body;
  margin-bottom: 10px;
}
.shipment-list__num {
  display: block;
  @extend .f_body;
  margin-bottom: 20px;
}
.shipment-list__cap {
  display: block;
  @extend .f_body;
  text-align: center;
  margin-top: 5px;
}

.message {
  @extend .f_body;
  text-align: center;
  padding: 60px 0;
}
</style>

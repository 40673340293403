<template>
  <li class="orderItem">
    <div class="name">
      <span class="label" v-if="unread">未読</span>
      {{ name }}
    </div>

    <div class="period">{{ beforePeriod }}</div>
    <div class="btn">
      <router-link
        :to="{
          path: url,
          query: {
            shopID: id,
            date: beforeDate,
            shopName: name,
            tel: tel
          }
        }"
      >
        <btnS btnTxt="詳細を見る" color="positive1" />
      </router-link>
    </div>

    <div class="period">{{ period }}</div>
    <div class="btn">
      <router-link
        :to="{
          path: url,
          query: {
            shopID: id,
            date: date,
            shopName: name,
            tel: tel
          }
        }"
      >
        <btnS btnTxt="詳細を見る" color="positive1" />
      </router-link>
    </div>
  </li>
</template>

<script>
import btnS from "@/components/btn/btnS";
import Global from "@/components/mixins/Global.vue";

export default {
  name: "orderItem",
  components: {
    btnS
  },
  mixins: [Global],
  props: {
    unread: {
      type: Boolean,
      default: false
    },
    id: String,
    date: String,
    name: String,
    tel: String,
    period: String,
    status: Boolean,
    url: String,
    extra: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      beforePeriod: "",
      beforeDate: ""
    };
  },
  created() {
    let date = new Date(this.date);
    // if (!this.extra) {
    //   date.setDate(date.getDate() - 7);
    // }
    date.setDate(date.getDate() - 7);
    this.beforeDate = this.ymdFormat(date);
    const endDate = new Date(this.beforeDate);
    endDate.setDate(endDate.getDate() + 6);
    this.beforePeriod = this.mdFormat(date) + "～" + this.mdFormat(endDate);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

.orderItem {
  border-bottom: solid 1px variables.$bg-gray-2;
  min-height: 60px;
  display: flex;
  align-items: center;
  //padding: 0 30px 0 0;
  color: variables.$txt-default;
  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }
}

.label {
  color: variables.$negative;
  border: solid 1px variables.$negative;
  box-sizing: border-box;
  margin-right: 15px;
  width: 48px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  width: 300px;
  height: 100%;
  min-height: 60px;
  box-sizing: border-box;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.period {
  border-left: solid 1px variables.$bg-gray-2;
  border-right: solid 1px variables.$bg-gray-2;
  width: 130px;
  height: 100%;
  min-height: 60px;
  box-sizing: border-box;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.btn {
  //margin-left: auto;
  display: flex;
  box-sizing: border-box;
  padding: 0 15px;
}

.status {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.status-btn {
  font-size: 1.3rem;
  letter-spacing: 0.1em;
  box-sizing: border-box;
  padding: 0 10px;

  border: solid 1px variables.$txt-default;
  height: 28px;
  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div class="orderMenu">
    <div class="orderMenu-meta">
      <span class="orderMenu-date">
        {{ month }}月{{ date }}日（{{ thisWeekDay }}）
      </span>
      <div class="orderMenu-time">
        <div v-on:click="changeTime({ order: order }, 'pm')">
          <btnToggle
            class="order-state__btn"
            btnTxt="指定日PM"
            color="primary"
            :class="{ on: order[orderNum].thisDayTime == 'pm' }"
          />
        </div>
        <div v-on:click="changeTime({ order: order }, 'am')">
          <btnToggle
            class="order-state__btn"
            btnTxt="翌日AM"
            color="negative"
            :class="{ on: order[orderNum].thisDayTime == 'am' }"
          />
        </div>
      </div>
    </div>

    <div class="orderMenu-list">
      <div
        class="orderMenu-list__item"
        v-for="product in products"
        :key="product.item_id"
        :class="{ positive: product.quantity > 0 }"
      >
        <span class="orderMenu-name">
          <span
            class="label-deadline over"
            v-if="extra && extraPeriod - product.deadlineAddOrder < 0"
          >
            受付終了
          </span>
          <span
            class="label-deadline today"
            v-if="extra && extraPeriod - product.deadlineAddOrder == 0"
          >
            本日締切
          </span>

          {{ product.name }}
        </span>
        <div class="orderMenu-amount">
          <input
            type="number"
            class="orderMenu-amount__num"
            @change="quantityChange(product, orderNum)"
            v-model="product.quantity"
          />
          <span class="orderMenu-amount__unit">{{ product.unit }}</span>
        </div>
        <span
          @click="
            minus(product, {
              itemID: product.item_id,
              index: orderNum
            })
          "
          class="orderMenu-amount__btn orderMenu-amount__minus"
        >
        </span>
        <span
          @click="
            plus(product, {
              targetProduct: product,
              event: $event,
              index: orderNum
            })
          "
          class="orderMenu-amount__btn orderMenu-amount__plus"
        >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapMutations } from 'vuex'
import btnToggle from "@/components/btn/btnToggle";
export default {
  name: "orderMenu",
  data: function() {
    return {
      today: "",
      extraPeriod: "", //追加発注の期限
      vacuumPeriod: "" //真空の期限 発送日の前日
    };
  },
  computed: {
    order: function() {
      return this.$store.state.shopOrder.data.order;
    },
    transferDays: function() {
      //配達日数 //shopの都道府県でtransferのdaysを検索
      return this.$store.state.shopOrder.data.transferDays;
    }
    // products : function(){
    //   return this.$store.state.products
    // },
  },
  props: {
    year: Number,
    month: Number,
    date: Number,
    thisWeekDay: String,
    orderNum: Number,
    extra: Boolean,
    products: Array,
    minimumQuantity: Number
  },
  components: {
    btnToggle
  },
  mounted() {
    const thisDay = new Date(this.year, this.month - 1, this.date);
    // let weekday = thisDay.getDay();
    // this.thisWeekDay = [ "日", "月", "火", "水", "木", "金", "土" ][weekday]

    //今日の日付
    const getDate = new Date();
    const y = getDate.getFullYear();
    const m = getDate.getMonth();
    const d = getDate.getDate();
    this.today = new Date(y, m, d);
    // this.today = new Date(2022, 1, 6);

    //追加発注の期限
    // 到着日 - 今日の日付 - 配達日数 なか日 - 配達日数（基準日）
    this.extraPeriod =
      (thisDay - this.today) / 86400000 + this.transferDays - 1;
    //真空の期限
    // 到着日 - 今日の日付 - 配達日数 なか日 - 配達日数（基準日）
    this.vacuumPeriod =
      (thisDay - this.today) / 86400000 + this.transferDays - 1;
  },
  methods: {
    // ...mapMutations(["plus", "minus"]),
    changeTime(order, time) {
      order.order[this.orderNum].thisDayTime = time;
    },
    quantityChange(product, index) {
      // 0以下にならないようにする
      if (product.quantity < 0) {
        product.quantity = 0;
      }
      this.$store.commit("mathTotalData", { index: index });
    },
    plus(product, param) {
      if (this.extra && this.extraPeriod - product.deadlineAddOrder < 0) {
        return;
      }
      console.log("product.orderUnit");
      console.log(product.orderUnit);
      console.log("product.orderUnitWeight");
      console.log(product.orderUnitWeight);
      // 発注単位の数だけ増やす
      product.quantity = product.quantity + product.orderUnit;
      // 0以上最小発注量以下の場合は最小発注量にする
      if (product.quantity > 0 && product.quantity < product.minimumQuantity) {
        product.quantity = product.minimumQuantity;
      }
      this.quantityChange(product, param.index);
      // this.$store.commit("plus", param);
      // this.$store.commit("mathTotalData", { index: param.index });
    },
    minus(product, param) {
      // 発注単位の数だけ減らす
      product.quantity = product.quantity - product.orderUnit;
      // 0以上最小発注量以下の場合は0にする
      if (product.quantity > 0 && product.quantity < product.minimumQuantity) {
        product.quantity = 0;
      }
      // 真空数量が実際の数量より大きくなった場合は、真空数量を数量に変更
      if (product.vacuumQuantity > product.quantity) {
        product.vacuumQuantity = product.quantity;
      }

      this.quantityChange(product, param.index);

      // this.$store.commit("minus", param);
      // this.$store.commit("mathTotalData", { index: param.index });
    }
  }
};
</script>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.orderMenu {
  width: 100%;
  position: relative;
  &.seeing {
    .order-state {
      transform: translateY(0);
    }
  }
}
.orderMenu-meta {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-right: 15px;
}
.orderMenu-time {
  display: flex;

  .order-state__btn {
    margin-left: 10px;
  }
}
.orderMenu-date {
  width: 100%;
  height: 60px;
  display: block;
  @extend .f_pageTtl;
  box-sizing: border-box;
  padding: 0 15px;
  display: flex;
  align-items: center;
  margin-right: auto;
}
.holiday {
  .orderMenu-date {
    color: variables.$sunday;
  }
}
.saturday {
  .orderMenu-date {
    color: variables.$saturday;
  }
}
.orderMenu-list__item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: variables.$bg-gray-1;
  box-sizing: border-box;
  padding-left: 17px;
  border-bottom: solid 1px variables.$bg-gray-2;
  position: relative;

  .orderMenu-name,
  .orderMenu-amount {
    opacity: 0.2;
    transition: all 0.5s ease-in-out;
  }
  &.positive {
    .orderMenu-name,
    .orderMenu-amount {
      opacity: 1;
    }
  }
}
.orderMenu-name {
  @extend .f_body;
  width: 175px;
  margin-right: auto;
}
.orderMenu-amount {
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 135px;
  top: -30%;
  height: 100%;
  input {
    pointer-events: none;
  }
}
.orderMenu-amount__num {
  //width: 35px;
  @extend .f_number;
  text-align: right;
  padding-right: 5px;
  box-sizing: border-box;
  pointer-events: none;
}
.orderMenu-amount__unit {
  @extend .f_body;
  white-space: nowrap;
}
.orderMenu-amount__btn {
  width: 60px;
  height: 60px;
  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 2;
  &:before {
    content: "";
    width: 12px;
    height: 1px;
    background: variables.$scrollbar-back;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.orderMenu-amount__minus {
  border-right: solid 1px variables.$border-1;
  margin-left: 15px;
}
.orderMenu-amount__plus {
  &:after {
    content: "";
    width: 1px;
    height: 12px;
    background: variables.$scrollbar-back;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.label-deadline {
  position: relative;

  z-index: 3;
  font-size: 11px;
  font-weight: 700;
  color: #fff;

  width: 53px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.over {
    background: variables.$txt-default;
  }
  &.today {
    background: variables.$negative;
  }
}
</style>

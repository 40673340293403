<template>
  <div class="address">
    <Header @change_shop="changeShop" />
    <pageTitle txt="納品先登録" :backBtn="backBtn" />
    <div class="address-input">
      <span v-if="id == ''" class="address-input__label">新規納品先登録</span>
      <span v-if="id != ''" class="address-input__label">納品先変更</span>
      <span class="each-label">屋号</span>
      <input type="text" placeholder="屋号" v-model="yago" />
      <div class="error">{{ errors.yago }}</div>

      <span class="each-label">略式名</span>
      <input type="text" placeholder="略式名" v-model="name" />
      <div class="error">{{ errors.name }}</div>

      <span class="each-label">郵便番号</span>
      <input
        type="text"
        placeholder="郵便番号"
        max-length="8"
        v-model="zipcode"
        @change="searchZipCode"
      />
      <div class="error">{{ errors.zipcode }}</div>

      <span class="each-label">住所</span>
      <div class="select">
        <select v-model="pref_id">
          <option value="">選択してください</option>
          <option v-for="pref in prefs" :key="pref.id" :value="pref.id">
            {{ pref.name }}
          </option>
        </select>
      </div>
      <div class="error">{{ errors.pref_id }}</div>
      <input type="text" placeholder="住所1" v-model="address1" />
      <div class="error">{{ errors.address1 }}</div>
      <textarea placeholder="住所2" v-model="address2" />
      <div class="error">{{ errors.address2 }}</div>

      <span class="each-label">電話番号</span>
      <input type="text" placeholder="電話番号" v-model="tel" />
      <div class="error">{{ errors.tel }}</div>

      <span class="each-label">佐川コードNo.</span>
      <input type="text" placeholder="佐川コードNo." v-model="sagawa_code" />
      <div class="error">{{ errors.sagawa_code }}</div>

      <btnS class="save" btnTxt="登録する" color="positive1" @click="goSave" />
      <btnS
        v-if="id != ''"
        class="delete"
        btnTxt="削除する"
        color="positive1"
        @click="goDelete"
      />
    </div>
    <span class="shop-list__label" v-if="deliveries.length > 0">
      納品先一覧
    </span>
    <ul class="shop-list">
      <li
        class="shop-list__item"
        v-for="(delivery, key) in deliveries"
        :key="key"
        @click="edit(delivery)"
      >
        <span class="name">{{ delivery.name }}</span>
        <btnS class="btn" btnTxt="編集/削除" color="positive1" />
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.address-input {
  box-sizing: border-box;
  padding: 40px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.address-input__label {
  width: 100%;
  display: block;
  @extend .f_pageTtl;
  margin-bottom: 20px;
  box-sizing: border-box;
  text-align: center;
}
input[type="text"],
input[type="number"],
select,
textarea {
  width: 100%;
  border-radius: 3px;
  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  border: 1px solid #ededed;
  box-sizing: border-box;
  @include mixin.placeholderColor(#cbcbcb);
  margin-bottom: 10px;
  transform: scale(0.81);
  font-size: 1.6rem;
  color: colors.$black;
}
input[type="text"],
input[type="number"],
select {
  height: 40px;
  padding: 0 15px;
}
textarea {
  height: 100px;
  padding: 10px 15px;
}
.shop-list__label {
  width: 100%;
  display: block;
  @extend .f_pageTtl;
  height: 40px;
  margin-bottom: 20px;
  box-sizing: border-box;
  text-align: center;
}
.shop-list {
  padding: 0 30px 20px;
  width: 100%;
  box-sizing: border-box;
}
.shop-list__item {
  margin-bottom: 20px;
  min-height: 60px;
  border-bottom: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 10px 0;
  cursor: pointer;
  @extend .f_body;
  display: flex;
  align-items: center;
  .name {
    width: calc(100% - 120px);
  }
  .btn {
    margin-left: auto;
  }
}
button {
  margin-top: 20px;
  margin-bottom: 20px;
}

.each-label {
  display: block;
  width: 100%;
  transform: scale(0.81);
  font-size: 1.6rem;
}

.select {
  width: 100%;

  position: relative;

  &:after {
    content: "";
    pointer-events: none;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: variables.$txt-default transparent transparent transparent;
    position: absolute;
    top: calc(50% - 5px);
    right: calc(10% + 10px);
    transform: translate(0, -50%);
  }
}
</style>

<script>
// @ is an alias to /src
import Header from "@/components/shop/header";
import pageTitle from "@/components/shop/pageTitle";
import btnS from "@/components/btn/btnS";
import ApiClient from "@/module/api";

export default {
  name: "ShopAddress",
  components: {
    Header,
    pageTitle,
    btnS
  },
  data: function() {
    return {
      backBtn: true,
      deliveries: [],
      id: "",
      yago: "",
      name: "",
      zipcode: "",
      pref_id: "",
      address: "",
      tel: "",
      sagawa_code: "",
      errors: {},
      prefs: []
    };
  },
  async created() {
    this._redraw();
    this.prefs = await this.$store.getters.prefectures;
  },
  methods: {
    changeShop() {
      this._redraw();
    },
    async goSave() {
      const loginData = await this.$store.getters[`getStoreLoginData`];
      const param = {
        store_id: loginData.shop_id,
        yago: this.yago,
        name: this.name,
        zipcode: this.zipcode,
        pref_id: this.pref_id,
        address1: this.address1,
        address2: this.address2,
        tel: this.tel,
        sagawa_code: this.sagawa_code
      };
      if (this.id !== "") {
        param.id = this.id;
      }
      const api = new ApiClient();
      const result = await api.post("/store/delivery/save", param);
      const errorResult = api.getErrors(result);
      if (errorResult.isHit) {
        this.errors = errorResult.errors;
      } else {
        this.clearInputData();
        this._redraw();
      }
    },
    edit(row) {
      this.id = row.id;
      (this.yago = row.yago), (this.name = row.name);
      this.zipcode = row.zipcode;
      this.pref_id = row.pref_id;
      this.address1 = row.address1;
      this.address2 = row.address2;
      this.tel = row.tel;
      this.sagawa_code = row.sagawa_code;
    },
    async searchZipCode() {
      const api = new ApiClient();
      let address = await api.searchZip(this.zipcode);
      address = address[0];
      this.pref_id = address.prefcode;
      this.address1 = address.address2;
      this.address2 = address.address3;
    },
    async goDelete() {
      if (confirm("この納品先を削除しますか？")) {
        const param = {
          id: this.id
        };
        const api = new ApiClient();
        await api.post(`/store/delivery/delete`, param);
        this.clearInputData();
        this._redraw();
      }
    },
    async _redraw() {
      const loginData = await this.$store.getters[`getStoreLoginData`];
      const param = {
        store_id: loginData.shop_id
      };
      const api = new ApiClient();
      const result = await api.post(`/store/delivery/list`, param);
      this.deliveries = result.list;
    },
    clearInputData() {
      this.id = "";
      this.yago = "";
      this.name = "";
      this.zipcode = "";
      this.pref_id = "";
      this.address1 = "";
      this.address2 = "";
      this.tel = "";
      this.sagawa_code = "";
    }
  }
};
</script>

<template>
  <div>
    <Header />
    <pageTitle txt="商品 一覧" :backBtn="backBtn" />
    <div id="wrapper">
      <ul class="list">
        <li class="list-head">
          <div class="list-head__item col-name">商品名</div>
          <div class="list-head__item col-unit">単位</div>
          <div class="list-head__item col-orderUnit">発注単位</div>
          <div class="list-head__item col-orderUnitWeight">
            発注単位<br />1あたりの重量<br />
            <span class="small">単位：g</span>
          </div>
          <div class="list-head__item col-price">
            発注単位<br />1あたりの単価
          </div>
          <div class="list-head__item col-minimumQuantity">
            最小<br />発注量
          </div>
          <div class="list-head__item col-vacuumCapacity">
            真空<br />袋の容量
          </div>
          <div class="list-head__item col-vacuumPrice">真空<br />袋の価格</div>
          <div class="list-head__item col-deadlineAddOrder">
            追加発注<br />受付期限
          </div>
          <div class="list-head__item col-taxClass">税区分</div>
          <div class="list-head__item col-asuhareDiscount">
            アスハレ<br />への<br />割引
          </div>
          <div class="list-head__item col-btn">
            <div v-on:click="showNew()" class="add-btn">
              <btnS class="add" btnTxt="新規登録" color="primary" />
            </div>
          </div>
          <div class="list-head__item col-sort">並べ替え</div>
        </li>
        <li class="list-item" v-for="product in products" :key="product.id">
          <div class="list-item__data col-name">{{ product.name }}</div>
          <div class="list-item__data col-unit">{{ product.unit }}</div>
          <div class="list-item__data col-orderUnit">
            {{ product.orderUnit }}
          </div>
          <div class="list-item__data col-orderUnitWeight">
            {{ product.orderUnitWeight }}
          </div>
          <div class="list-item__data col-price">{{ product.price }}</div>
          <div class="list-item__data col-minimumQuantity">
            {{ product.minimumQuantity }}
          </div>
          <div class="list-item__data col-vacuumCapacity">
            {{ product.vacuumCapacity }}
          </div>
          <div class="list-item__data col-vacuumPrice">
            {{ product.vacuumPrice }}
          </div>
          <div class="list-item__data col-deadlineAddOrder">
            {{ product.deadlineAddOrder }}日前
          </div>
          <div class="list-item__data col-taxClass">{{ product.taxClass }}</div>
          <div class="list-head__item col-asuhareDiscount">
            <span v-if="product.asuhareDiscount">適用</span>
            <span v-else>ー</span>
          </div>
          <div class="list-item__data col-btn">
            <div v-on:click="showEdit(product)">
              <btnS class="edit" btnTxt="編集" color="positive1" />
            </div>
            <span class="delete-txt" @click="onDelete(product)">削除</span>
          </div>
          <div class="list-head__item col-sort">
            <sortUp @click="sortUp(product)" />
            <sortDown @click="sortDown(product)" />
          </div>
        </li>
      </ul>
    </div>
    <div class="pager-wrap">
      <pager :page="page" @movePage="movePage" />
    </div>
    <div id="overlay" :class="{ visible: overlay }">
      <div id="add" v-if="add">
        <div class="dialog">
          <p class="dialog-ttl" v-if="id == ''">新規商品登録</p>
          <p class="dialog-ttl" v-if="id != ''">商品情報編集</p>
          <div class="dialog-form">
            <div class="dialog-form__item name">
              <label>商品名</label>
              <input type="text" v-model="name" />
              <div class="error">{{ errors.name }}</div>
            </div>
            <div class="dialog-form__item unit">
              <label>単位</label>
              <input type="text" v-model="unit" />
              <div class="error">{{ errors.unit }}</div>
            </div>
            <div class="dialog-form__item orderUnit">
              <label>発注単位</label>
              <input type="text" v-model="order_unit" />
              <div class="error">{{ errors.order_unit }}</div>
            </div>
            <div class="dialog-form__item orderUnitWeight">
              <label>発注単位1あたりの重量 単位：g</label>
              <input type="text" v-model="order_unit_weight" />
              <div class="error">{{ errors.order_unit_weight }}</div>
            </div>
            <div class="dialog-form__item price">
              <label>発注単位1あたりの単価</label>
              <input type="text" v-model="price" />
              <div class="error">{{ errors.price }}</div>
            </div>
            <div class="dialog-form__item minimumQuantity">
              <label>最小発注量</label>
              <input type="text" v-model="minimum_quantity" />
              <div class="error">{{ errors.minimum_quantity }}</div>
            </div>
            <div class="dialog-form__item vacuumCapacity">
              <label>真空袋の容量</label>
              <input type="text" v-model="vacuum_capacity" />
              <div class="error">{{ errors.vacuum_capacity }}</div>
            </div>
            <div class="dialog-form__item vacuumPrice">
              <label>真空袋の価格</label>
              <input type="text" v-model="vacuum_price" />
              <div class="error">{{ errors.vacuum_price }}</div>
            </div>
            <div class="dialog-form__item deadlineAddOrder">
              <label>追加発注受付期限</label>
              <input type="text" v-model="deadline_add_order" /> 日前
              <div class="error">{{ errors.deadline_add_order }}</div>
            </div>
            <div class="dialog-form__item taxClass">
              <label>税区分</label>
              <div class="select">
                <select v-model="tax_class">
                  <option value="8">8%</option>
                  <option value="10">10%</option>
                </select>
              </div>
              <div class="error">{{ errors.tax_class }}</div>
            </div>
            <div class="dialog-form__item asuhareDiscount">
              <label>アスハレへの割引</label>
              <input
                type="checkbox"
                id="asuhareDiscount"
                :checked="asuhareDiscount"
                @change="changeAsuhareDiscount"
              />
              <label for="asuhareDiscount" class="asuhareDiscountLabel">
                適用する
              </label>
            </div>
          </div>
        </div>
        <div class="overlay-btn">
          <div v-on:click="(add = !add), (overlay = !overlay)">
            <btnS btnTxt="戻る" color="back" />
          </div>
          <div>
            <btnL btnTxt="この内容で登録する" color="primary" @click="goSave" />
          </div>
        </div>
      </div>
      <div id="delete" v-if="delete_">
        <div class="dialog">
          <p class="dialog-ttl">商品情報削除</p>
          <div class="dialog-form">
            <div class="dialog-form__item">
              <p class="dialog-body center">
                「{{ name }}」を削除しようとしています。
                <br />
                本当に削除しますか？
              </p>
            </div>
          </div>
        </div>
        <div class="overlay-btn">
          <div @click="(delete_ = !delete_), (overlay = !overlay)">
            <btnS btnTxt="戻る" color="back" />
          </div>
          <div>
            <btnL btnTxt="削除する" color="negative" @click="goDelete" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

#add,
#edit,
#delete {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  box-sizing: border-box;
  width: 800px;
  padding: 30px 50px 50px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-body {
  font-size: 1.3rem;
  line-height: 1.5;
  letter-spacing: 0.05em;
  &.center {
    text-align: center;
  }
}

.dialog-form {
  display: flex;
  flex-wrap: wrap;
}

.dialog-form__item {
  @extend .f_caption;
  margin-top: 35px;
  width: 100%;
  &.name,
  &.orderUnitWeight {
    width: 220px;
  }
  &.unit,
  &.orderUnit,
  &.minimumQuantity,
  &.vacuumCapacity,
  &.vacuumPrice {
    width: 80px;
  }
  &.price {
    width: 135px;
  }
  &.deadlineAddOrder,
  &.taxClass {
    width: 110px;
    input {
      width: 44px;
    }
  }
  &.name,
  &.unit,
  &.orderUnit,
  &.price,
  &.minimumQuantity,
  &.vacuumCapacity,
  &.vacuumPrice,
  &.deadlineAddOrder {
    margin-right: 20px;
  }
  &.asuhareDiscount {
    input {
      display: none;
    }
    .asuhareDiscountLabel {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:before {
        content: "";
        box-sizing: border-box;
        border: solid 1px variables.$bg-gray-3;
        border-radius: 3px;
        background: #fff;
        width: 22px;
        height: 22px;
        display: inline-block;
        margin-right: 4px;
      }
      &:after {
        content: "";
        height: 0;
        left: 6px;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        transform-origin: top left;
        transition: all 0.15s ease-in-out, width 0.15s ease-in-out 0.15s;
        width: 0;
      }
    }
    input:checked + .asuhareDiscountLabel {
      &:after {
        border-bottom: 2px solid #c62828;
        border-left: 2px solid #c62828;
        height: 4px;
        width: 8px;
      }
    }
  }
  label {
    margin-bottom: 5px;
    display: block;
    height: 17px;
  }
  input,
  select {
    box-sizing: border-box;
    border: solid 1px variables.$bg-gray-3;
    border-radius: 3px;
    background: #fff;
    width: 100%;
    height: 28px;
    padding: 0 12px;
  }
  .select {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: variables.$txt-default transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }
  }
}

.overlay-btn {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  position: relative;
  margin: 0 auto;
  .buttonS {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.list {
  box-sizing: border-box;
  width: 1281px;
  margin-left: auto;
  margin-right: auto;
  border-left: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  @extend .f_caption;
  .small {
    font-size: 1rem;
  }
}

.list-head {
  display: flex;
  align-items: center;
  height: 75px;
}

.list-head__item {
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
}

.list-item {
  display: flex;
  align-items: center;
  height: 60px;
  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }
}

.list-item__data {
  display: flex;
  align-items: center;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 0 15px;
  &.col-unit {
    justify-content: center;
  }
  &.col-orderUnit {
    justify-content: flex-end;
  }
  &.col-orderUnitWeight {
    justify-content: flex-end;
  }

  &.col-price {
    justify-content: flex-end;
  }

  &.col-minimumQuantity {
    justify-content: flex-end;
  }

  &.col-vacuumCapacity,
  &.col-vacuumPrice,
  &.col-deadlineAddOrder,
  &.col-taxClass {
    justify-content: flex-end;
  }
}

.col-name {
  width: 235px;
}

.col-unit {
  width: 55px;
}

.col-orderUnit {
  width: 65px;
}

.col-orderUnitWeight {
  width: 80px;
}

.col-price {
  width: 80px;
}

.col-minimumQuantity,
.col-asuhareDiscount {
  width: 75px;
}

.col-vacuumCapacity,
.col-vacuumPrice,
.col-deadlineAddOrder,
.col-taxClass {
  width: 65px;
}

.col-btn {
  width: 250px;
  justify-content: flex-end;
  flex-direction: row;
  box-sizing: border-box;
}

.col-sort {
  width: 100px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.add-btn {
  margin-right: 105px;
}

.delete-txt {
  color: variables.$negative;
  margin: 0 15px 0 45px;
  display: inline-block;
  width: 30px;
  cursor: pointer;
}

.pager-wrap {
  width: 900px;
  margin: 0 auto;
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import sortUp from "@/components/btn/sortUp";
import sortDown from "@/components/btn/sortDown";
import pager from "@/components/common/pager";
import ApiClient from "@/module/api";

export default {
  name: "product",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL,
    sortUp,
    sortDown,
    pager
  },
  data: function() {
    return {
      backBtn: true,
      add: false,
      delete_: false, //'delete'は予約語か何かで使えない？
      overlay: false,
      id: "",
      page: {},
      pageNumber: 1,
      products: {},
      errors: {},
      name: "",
      unit: "",
      order_unit: "",
      order_unit_weight: "",
      price: "",
      minimum_quantity: "",
      vacuum_capacity: "",
      vacuum_price: "",
      deadline_add_order: "",
      tax_class: "8",
      asuhareDiscount: false
    };
  },
  async mounted() {
    this._redraw();
  },
  // computed: {
  //   products : function(){
  //     return this.$store.state.products
  //   },
  // },
  methods: {
    showNew() {
      this.id = "";
      this.name = "";
      this.unit = "";
      this.order_unit = "";
      this.order_unit_weight = "";
      this.price = "";
      this.minimum_quantity = "";
      this.vacuum_capacity = "";
      this.vacuum_price = "";
      this.deadline_add_order = "";
      this.tax_class = "8";
      this.add = true;
      this.overlay = true;
    },
    showEdit(product) {
      this.id = product.id;
      this.name = product.name;
      this.unit = product.unit;
      this.order_unit = product.orderUnit;
      this.order_unit_weight = product.orderUnitWeight;
      this.price = product.price;
      this.minimum_quantity = product.minimumQuantity;
      this.vacuum_capacity = product.vacuumCapacity;
      this.vacuum_price = product.vacuumPrice;
      this.deadline_add_order = product.deadlineAddOrder;
      this.tax_class = product.taxClass;
      this.asuhareDiscount = product.asuhareDiscount;
      this.add = true;
      this.overlay = true;
    },
    async goSave() {
      this.errors = {};
      const param = {
        name: this.name,
        unit: this.unit,
        order_unit: this.order_unit,
        order_unit_weight: this.order_unit_weight,
        price: this.price,
        minimum_quantity: this.minimum_quantity,
        vacuum_capacity: this.vacuum_capacity ? this.vacuum_capacity : 0,
        vacuum_price: this.vacuum_price ? this.vacuum_price : 0,
        deadline_add_order: this.deadline_add_order,
        tax_class: this.tax_class,
        asuhare_discount: this.asuhareDiscount ? 1 : 0
      };
      if (this.id) {
        param.id = this.id;
      }
      const api = new ApiClient();
      const result = await api.post("/iseya/item/save", param);
      const checkResult = api.getErrors(result);
      if (checkResult.isHit) {
        this.errors = checkResult.errors;
        return;
      }
      this.add = false;
      this.overlay = false;
      this._redraw();
    },
    async onDelete(product) {
      this.id = product.id;
      this.name = product.name;
      this.overlay = true;
      this.delete_ = true;
    },
    async goDelete() {
      const param = {
        id: this.id
      };
      const api = new ApiClient();
      await api.post(`/iseya/item/delete`, param);
      this.overlay = false;
      this.delete_ = false;
      this._redraw();
    },
    async sortUp(product) {
      const param = {
        id: product.id,
        mode: "up"
      };
      const api = new ApiClient();
      await api.post(`/iseya/item/sort`, param);
      this._redraw();
    },
    async sortDown(product) {
      const param = {
        id: product.id,
        mode: "down"
      };
      const api = new ApiClient();
      await api.post(`/iseya/item/sort`, param);
      this._redraw();
    },
    movePage(page) {
      this.pageNumber = page;
      this._redraw();
    },
    changeAsuhareDiscount(v) {
      this.asuhareDiscount = v.target.checked;
    },
    async _redraw() {
      const api = new ApiClient();
      const result = await api.get(`/iseya/item/list/${this.pageNumber}`);
      this.products = result.list;
      this.page = result.page;
    }
  }
};
</script>

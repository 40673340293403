<template>
  <div>
    <Header />
    <pageTitle txt="週間種類別集計" :backBtn="backBtn" />
    <div id="wrapper">
      <ul class="list">
        <orderWeeklyItem
          v-for="(day, key) in list"
          :key="key"
          class="holiday"
          :stYear="day.stYear"
          :stMonth="day.stMonth"
          :stDay="day.stDay"
          :endYear="day.endYear"
          :endMonth="day.endMonth"
          :endDay="day.endDay"
          :noorder="day.count"
          :stores="day.stores"
          :order="true"
          :extra="false"
          :index="key"
          url="/iseya/order/weekly/detail/"
        />
      </ul>
    </div>
    <div class="pager-wrap">
      <pager />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
}

.pager-wrap {
  width: 900px;
  margin: 0 auto;
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import orderWeeklyItem from "@/components/iseya/orderWeeklyItem";
import pager from "@/components/common/pager";
import ApiClient from "@/module/api";

export default {
  name: "weekly",
  components: {
    Header,
    pageTitle,
    orderWeeklyItem,
    pager
  },
  data: function() {
    return {
      backBtn: true,
      list: []
    };
  },
  async created() {
    const api = new ApiClient();
    const result = await api.get("/iseya/order/weekly");
    this.list = result.list;
  }
};
</script>

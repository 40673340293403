<template>
  <div class="shop">
    <div id="title-area">
      <div class="logo"><img alt="" src="../../assets/logo@2x.png" /></div>
      <span class="version">受発注管理システム version 1.0.0 </span>
    </div>
    <div class="shop-list">
      <!-- <router-link to="/home/"> -->
      <btnL
        v-for="(shop, key) in shops"
        :key="key"
        class="shop-list__item"
        :btnTxt="shop.name"
        color="neutral"
        @click="selectShop(shop)"
      />
      <!-- </router-link> -->
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#title-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media.max-screen(media.$breakpoint-sm) {
    padding: 40px 0 80px;
  }
  @include media.min-screen(media.$breakpoint-md) {
    padding: 100px 0 80px;
  }
}
.logo {
  width: 230px;
  height: 47px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.version {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.06em;
  line-height: 34px;
  text-align: center;
  color: #3b4043;
}
.shop-list {
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 315px;
  margin-left: auto;
  margin-right: auto;
}
.shop-list__item {
  margin-bottom: 20px;
  @extend .f_btnTxt;
}
</style>

<script>
import btnL from "@/components/btn/btnL";
import ApiClient from "@/module/api";

export default {
  name: "ShopSelect",
  components: {
    btnL
  },
  data: function() {
    return {
      shops: []
    };
  },
  async created() {
    const api = new ApiClient();
    const result = await api.get(`/store/store/list`);
    this.shops = result.list;
  },
  methods: {
    async selectShop(shop) {
      this.$store.dispatch(`setSelectShop`, shop);
      this.$router.push(`/home/`);
    }
  }
};
</script>

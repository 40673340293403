<template>
  <div id="pageTitle-area">
    <div class="back" v-if="backBtn" @click="back">戻る</div>
    <div class="back" v-if="jump" @click="jump">戻る</div>
    <div id="pageTitle">{{ txt }}</div>
  </div>
</template>

<script>
export default {
  name: "pageTitle",
  props: {
    txt: String,
    backURL: {
      type: String,
      default: ""
    },
    backBtn: {
      type: Boolean,
      default: true
    },
    jump: Function
  },
  methods: {
    back() {
      if (this.backURL) {
        this.$router.push(this.backURL);
        return;
      }
      history.back();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#pageTitle-area {
  width: 100%;
  height: 50px;
  background: variables.$bg-gray-1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px variables.$bg-gray-3;
  position: relative;
}
#pageTitle {
  @extend .f_pageTtl;
}
.back {
  position: absolute;
  @extend .f_btnTxt;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>

<script>
import ApiClient from "@/module/api";
export default {
  methods: {
    async normalReaded() {
      const api = new ApiClient();
      const date = new Date();
      let span = 0;
      if (date.getDay() == 0) {
        span++;
      } else if (date.getDay() > 1) {
        //span += 7 - date.getDay() + 1;
        span += 1 - date.getDay();
        const isNextWeek = await api.get("/is_next_week");
        if (isNextWeek.result == 1) {
          span += 7;
        }
      }
      date.setDate(date.getDate() + span);
      this.date = new Date(date);
      this.startDate = date.getMonth() + 1 + "/" + date.getDate();
      date.setDate(date.getDate() + 6);
      this.endDate = date.getMonth() + 1 + "/" + date.getDate();

      const param = {
        start_date: this.ymdFormat(this.date)
      };
      const result = await api.post("/iseya/order/list", param);
      return result.list;
    },
    async extraReaded() {
      const api = new ApiClient();
      const date = new Date();
      let span = 0;
      if (date.getDay() == 0) {
        span++;
      } else if (date.getDay() == 2) {
        span += -date.getDay() + 1;
        const isNextWeek = await api.get("/is_next_week");
        if (isNextWeek.result == 1) {
          span += 7;
        }
      } else if (date.getDay() > 1) {
        span += 7 - date.getDay() + 1;
      }
      date.setDate(date.getDate() + span);
      this.date = new Date(date);
      this.startDate = date.getMonth() + 1 + "/" + date.getDate();
      date.setDate(date.getDate() + 6);
      this.endDate = date.getMonth() + 1 + "/" + date.getDate();

      const param = {
        start_date: this.ymdFormat(this.date),
        extra: 1
      };
      const result = await api.post("/iseya/order/list", param);
      return result.list;
    }
  }
};
</script>

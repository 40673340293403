<template>
  <header id="header">
    <div class="header-inner">
      <router-link to="/home/" class="logo">
        <img alt="" src="../../assets/logo@2x.png" />
      </router-link>
      <div v-if="is_owner === '1'" class="shop-select">
        <select v-model="select_shop" @change="changeShop">
          <option v-for="(shop, key) in shops" :key="key" :value="shop.id">
            {{ shop.name }}
          </option>
        </select>
      </div>
    </div>
  </header>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#header {
  width: 100%;
  height: 40px;
  background: #fff;
  display: flex;
  justify-content: center;
}
.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 0 10px;
}
.logo {
  width: 110px;
}
.shop-select {
  position: relative;
  width: 160px;
  height: 100%;
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: variables.$txt-default transparent transparent transparent;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
select {
  font-size: 1.6rem;
  transform: scale(0.75);
  color: variables.$txt-default;
  width: 235px;
  height: 53px;
  box-sizing: border-box;
  position: absolute;
  right: 1px;
  top: 0px;
  transform-origin: top right;
}
</style>

<script>
import ApiClient from "@/module/api";

export default {
  name: "shopHeader",

  data() {
    return {
      is_owner: "0",
      select_shop: "",
      shops: []
    };
  },

  async created() {
    const state = await this.$store.getters["getStoreLoginData"];
    this.is_owner = state.is_owner;
    this.select_shop = state.shop_id;

    const api = new ApiClient();
    const result = await api.get("/store/store/list");
    this.shops = result.list;
  },

  methods: {
    changeShop() {
      for (let i = 0; i < this.shops.length; i++) {
        const shop = this.shops[i];
        if (shop.id === this.select_shop) {
          this.$store.dispatch("setSelectShop", shop);
          this.$emit("change_shop", shop);
          break;
        }
      }
    }
  }
};
</script>

<template>
  <div class="order-state" v-if="order.length > 0">
    <div class="order-state__inner">
      <div class="order-state__weight">
        <span class="order-state__label">合計重量</span>
        <span class="order-state__num">
          <span class="order-state__value">
            {{ order[activeDay].thisDayWeight / 1000 }}
          </span>
          <span class="order-state__unit">kg</span>
        </span>
      </div>
      <div class="order-state__box">
        <span class="order-state__label">予想箱数</span>
        <span class="order-state__num">
          <span class="order-state__value">
            {{ order[activeDay].thisDayBoxQt }}
          </span>
          <span class="order-state__unit">個</span>
        </span>
      </div>
      <span class="order-state__destination">納品先：{{ address }}</span>
      <a @click="jump">
        <btnS
          class="order-state__btn"
          btnTxt="発注内容を確認"
          color="primary"
        />
      </a>
    </div>
    <div id="overlay" :class="{ visible: overlay }">
      <div id="overlay-window">
        <div class="dialog">
          <p class="dialog-body">
            発送処理が開始されているため<br />
            ロックされています。
          </p>
          <p class="dialog-body">
            発注内容を変更したい場合は<br />
            有限会社イセヤ（担当：{{ IseyaManager }}）まで<br />
            電話でお問い合わせください。
          </p>
          <div class="overlay-btn">
            <a :href="'tel:' + IseyaTel" class="overlay-btn__tel">
              <btnM btnTxt="電話で問い合わせ" color="positive1" />
            </a>
            <div v-on:click="overlay = !overlay">
              <btnS btnTxt="閉じる" color="back" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import btnS from "@/components/btn/btnS";
import btnM from "@/components/btn/btnM";
import Const from "@/module/const";

export default {
  name: "orderState",
  data: function() {
    return {
      overlay: false,
      //overlay: true,
      isExtra: false,
      lock: this.checkRock
    };
  },
  props: {
    activeDay: Number,
    jumpTo: String,
    address: String,
    extra: Boolean,
    checkRock: Boolean
  },
  components: {
    btnS,
    btnM
  },
  created() {
    this.isExtra = this.extra;
  },
  computed: {
    order: function() {
      return this.$store.state.shopOrder.data.order;
    },
    IseyaTel: function() {
      return Const.IseyaTel;
    },
    IseyaManager: function() {
      return Const.IseyaManager;
    },
    today: function() {
      //今日の日付
      const getDate = new Date();
      const y = getDate.getFullYear();
      const m = getDate.getMonth();
      const d = getDate.getDate();
      return new Date(y, m, d);
    },
    //配達日数 //shopの都道府県でtransferのdaysを検索
    transferDays: function() {
      return this.$store.state.shopOrder.data.transferDays;
    }
  },
  methods: {
    jump: function() {
      //追加発注でロック後に今日締切分をオーダーしようとした時のチェック
      let order = this.$store.state.shopOrder.data.order;
      for (let $i = 0; $i < order.length; $i++) {
        let pr = order[$i].products;
        let thisDay = new Date(
          order[$i].year,
          order[$i].month - 1,
          order[$i].day
        ); //到着日
        //今日との日数差
        let expire = (thisDay - this.today) / 86400000 - this.transferDays; //到着日 - 今日の日付 - 配達日数 なか日 - 配達日数（基準日）
        for (let $ii = 0; $ii < pr.length; $ii++) {
          let aq = pr[$ii].add_quantity;
          let deadline = pr[$ii].deadlineAddOrder;
          if (expire === deadline && aq > 0) {
            console.log("--------------------------------------------");
            console.log(thisDay + "の" + pr[$ii].name + "が今日締切");
            console.log(aq);
            this.overlay = true;
          } else {
            this.$router
              .push({
                name: this.jumpTo,
                params: {
                  address: this.address
                }
              })
              .catch(() => {});
          }
        }
      }

      //通常発注の場合
      if (!this.isExtra) {
        this.$router
          .push({
            name: this.jumpTo,
            params: {
              address: this.address
            }
          })
          .catch(() => {});
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.order-state {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 90px;
  //background: variables.$bg-gray-2;
  background: transparent;
  //backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.01);
  display: flex;
  justify-content: center;
  transition: all 0.5s ease;
  z-index: 3;
}
.order-state__inner {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  position: relative;
  backdrop-filter: blur(5px);
  box-sizing: border-box;
  padding: 0 15px 0 25px;
}
.order-state__weight {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;
  margin-bottom: 15px;
}
.order-state__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  margin-right: auto;
}
.order-state__label {
  @extend .f_caption;
}
.order-state__destination {
  @extend .f_caption;
  position: absolute;
  left: 25px;
  bottom: 10px;
}
.order-state__num {
  position: relative;
  top: -3px;
}
.order-state__value {
  @extend .f_number;
  margin: 0 5px;
}
.order-state__unit {
  @extend .f_body;
}
.order-state__btn {
  margin-left: auto;
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

#overlay-window {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 60px);
}

.dialog {
  box-sizing: border-box;
  width: 100%;
  padding: 30px 30px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-body {
  @extend .f_body;
  margin-bottom: 15px;
  text-align: center;
}

.overlay-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.overlay-btn__tel {
  margin-bottom: 20px;
  button {
    white-space: nowrap;
  }
}
</style>

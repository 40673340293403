<template>
  <div>
    <Header />
    <pageTitle txt="通常発注 詳細" :backBtn="backBtn" backURL="/iseya/order/" />
    <div id="meta">
      <span class="shopName">{{ $route.query.shopName }}</span>
      <span class="shopTel">TEL : {{ $route.query.tel }}</span>
      <div class="meta-btn">
        <router-link
          :to="{
            path: '/iseya/order/detail/edit/',
            query: {
              shopID: $route.query.shopID,
              shopName: $route.query.shopName,
              tel: $route.query.tel,
              date: $route.query.date
            }
          }"
        >
          <btnS class="edit" btnTxt="編集" color="positive1" />
        </router-link>
        <div
          v-on:click="
            print = !print;
            setPrint();
          "
        >
          <btnS class="print" btnTxt="印刷" color="primary" />
        </div>
      </div>
    </div>
    <div id="wrapper">
      <ul class="list">
        <li class="list-head" v-if="orderList.length > 0">
          <ul class="product">
            <li class="date"></li>
            <li class="vacuum"></li>
            <li
              class="product-item"
              v-for="product in orderList[0].products"
              :key="product.item_id"
            >
              {{ product.name }}
            </li>
          </ul>
        </li>
        <orderDetailItem
          v-for="(item, key) in orderList"
          :key="item.id"
          class="weekday"
          :item="item"
          :products="item.products"
          :month="item.month"
          :date="item.day"
          :day="key"
          :orderNum="key"
          :dayName="item.weekName"
          :time="item.thisDayTime"
          :class="{
            saturday: item.weekName == '土',
            holiday: item.weekName == '日' || item.is_holiday == '1'
          }"
        />
      </ul>
    </div>
    <div id="overlay" :class="{ visible: print }">
      <div
        class="print-paper landscape"
        v-for="paper in this.printNum"
        :key="paper"
      >
        <div class="print-inner">
          <div id="print-meta">
            <p class="print-ttl">発注書（{{ $route.query.shopName }}）</p>
            <p class="print-period">
              {{ start_date | dateFormatShort }} ～
              {{ end_date | dateFormatShort }}
            </p>
            <span class="print-shopName">{{ $route.query.shopName }}</span>
            <span class="print-shopTel">TEL : {{ $route.query.tel }}</span>
            <div class="print-time">
              {{ today | dateFormatShort }} 現在
              <br />
              {{ paper }}/{{ printNum }}頁
            </div>
          </div>

          <ul class="list" v-if="orderList.length > 0">
            <li class="list-head">
              <ul class="product">
                <li class="date"></li>
                <li class="vacuum"></li>
                <li
                  class="product-item"
                  v-for="product in orderList[0].products.slice(
                    paper * dataPerPage - dataPerPage,
                    paper * dataPerPage
                  )"
                  :key="product.id"
                >
                  {{ product.name }}
                </li>
              </ul>
            </li>

            <orderDetailItemPrint
              v-for="(item, key) in orderList"
              :key="item.id"
              class="weekday"
              :products="item.products"
              :dataPerPage="dataPerPage"
              :index="paper"
              :pagePerItems="pagePerItems"
              :month="item.month"
              :date="item.day"
              :day="key"
              :orderNum="key"
              :dayName="item.weekName"
              :time="item.thisDayTime"
              :class="{
                saturday: item.weekName == '土',
                holiday: item.weekName == '日' || item.is_holiday == '1'
              }"
            />
          </ul>
        </div>
      </div>

      <div id="fixedMenu">
        <div
          v-on:click="
            print = !print;
            removeSetPrint();
          "
        >
          <btnS btnTxt="戻る" color="back" />
        </div>
        <div onclick="print()">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  box-sizing: border-box;
  padding: 30px 0 120px;
  overflow: scroll;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

#print-meta {
  margin-bottom: 20px;
}

.print-time {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-align: right;
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 15px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}
#meta {
  display: flex;
  align-items: center;
  width: 100%;
  height: 75px;
  background: #fcfcfc;
  box-sizing: border-box;
  padding: 0 30px 0 15px;
}

.meta-btn {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.shopName {
  font-size: 1.5rem;
  font-weight: 500; /* medium */
  letter-spacing: 0.06em;
  margin-right: 30px;
}

.shopTel {
  font-size: 1.5rem;
  font-weight: 500; /* medium */
  letter-spacing: 0.06em;
}

.status {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.status-btn {
  font-size: 1.3rem;
  letter-spacing: 0.1em;
  box-sizing: border-box;
  padding: 0 10px;

  border: solid 1px variables.$txt-default;
  height: 28px;
  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.edit {
  margin-left: 30px;
}

.print {
  margin-left: 30px;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.list {
  box-sizing: border-box;
  border-left: solid 1px variables.$bg-gray-2;
  //border-right: solid 1px variables.$bg-gray-2;
  @extend .f_body;
  margin-bottom: 20px;
  display: flex;
}

.list-head {
  width: 160px;
  min-width: 160px;
}

.date {
  height: 100px;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
}

.vacuum {
  height: 30px;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
}

.product-item {
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 0 15px;
  height: 60px;
  display: flex;
  align-items: center;
  &:nth-of-type(odd) {
    background: variables.$bg-gray-1;
  }
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import orderDetailItem from "@/components/iseya/orderDetailItem";
import orderDetailItemPrint from "@/components/iseya/orderDetailItemPrint";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "orderDetail",
  components: {
    Header,
    pageTitle,
    orderDetailItem,
    orderDetailItemPrint,
    btnS,
    btnL
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      today: "",
      start_date: "",
      end_date: "",
      print: false,
      shopName: "",
      orderList: [],
      rowNum: 1, //店舗数 / カラム数
      dataPerRow: 7, //1ページあたりのカラム数
      dataPerPage: 10, //1ページあたりのデータ数
      pagePerItems: 1, //商品1周分で何枚必要か
      printNum: 1 //印刷枚数 = 商品数 / dataPerPage * rowNum
    };
  },
  async created() {
    const param = {
      store_id: this.$route.query.shopID,
      start_date: this.$route.query.date,
      other_id: 0
    };
    const api = new ApiClient();
    const result = await api.post("/store/order/list/1", param);
    this.orderList = result.list;

    this.today = new Date();
    this.start_date = new Date(this.$route.query.date);
    this.end_date = new Date(this.$route.query.date);
    this.end_date.setDate(this.end_date.getDate() + 6);

    this.rowNum = 7;
    this.printNum = parseInt(
      this.orderList[0].products.length / this.dataPerPage
    );
    this.pagePerItems = parseInt(
      this.orderList[0].products.length / this.dataPerPage
    );
  },
  methods: {
    setPrint() {
      let html = document.querySelector("html");
      html.classList.add("print-landscape");
    },
    removeSetPrint() {
      let html = document.querySelector("html");
      html.classList.remove("print-landscape");
    }
  }
};
</script>

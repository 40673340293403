<template>
  <button class="sortDown" @click="click"></button>
</template>

<script>
export default {
  name: "sortDown",
  methods: {
    click(e) {
      this.$emit("click", e);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.sortDown {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  @extend .f_caption;

  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  border: 1px solid #cbcbcb;
  color: variables.$txt-default;

  position: relative;
  &:after {
    content: "";
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #cbcbcb;
    border-right: 2px solid #cbcbcb;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -70%) rotate(45deg);
  }
}
</style>
